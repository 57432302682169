import React, { useContext } from "react"
import "./navigation.scss"

// Authentication context provideers
import { AuthContext, logoutRequest } from "../../../utils/providers/auth"
import { auth } from "../../../utils/firebase"

// UI components
import Logo from "../../../assets/images/golf-holidays-direct-logo.png"
import { SignoutIcon } from "../../../utils/svgs"

// Make use of the profile image hook
import useProfileImage from "../../../hooks/useProfileImage"

// Navigation type imports for clients and staff
import ClientNav from "./types/client"
import StaffNav from "./types/staff"

// Returns the UI markup for the apps navigation
export default function Navigation() {
	// Pull the users profile object from the context
	const { user } = useContext(AuthContext)

	// Attempt to pull the profile picture if they have one
	const userProfilePicture = useProfileImage(auth.currentUser.uid)

	return (
		<nav id="app-navigation">
			<div id="nav-desktop">
				<div className="nav-container-upper">
					{/* Navigation logo */}
					<div className="nav-logo">
						<img
							src={Logo}
							alt="Golf Holiday Direct"
						/>
						<span className="nav-version">Booking system v2.13.51</span>
					</div>

					{/* Does we need to show the staff or client navigation? */}
					{user.is_staff ? (
						<StaffNav
							isAdmin={user.is_admin}
							isManager={user.is_manager}
							isCRMManager={user.crm_manager}
							hideTargets={user?.hide_targets}
						/>
					) : (
						<ClientNav />
					)}
				</div>

				{/* Profile picture and logout button */}
				<div
					className="nav-user"
					onClick={() => logoutRequest()}>
					<div className="nav-profile-image">
						{/* User profile picture URL or initials */}
						{userProfilePicture}
					</div>
					<div className="nav-user-name">
						<p>
							{user.first_name ? user.first_name + " " + user.last_name : auth.currentUser.displayName}
							<small>Logout</small>
						</p>
					</div>
					<div className="nav-user-signout">
						<SignoutIcon />
					</div>
				</div>
			</div>
		</nav>
	)
}
