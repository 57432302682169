import React, { useState } from "react"
import { db } from "../../utils/firebase"
import { useHistory } from "react-router-dom"
import firebase from "firebase"
import "./merge-clients.scss"

/**
 * UI components
 */
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Button from "../../components/ui/button/button"
import Input from "../../components/ui/inputs/input"
import Table from "../../components/structure/table/table"

/**
 * Functional component to merge two client accounts
 */
function MergeClients() {
	const history = useHistory()
	const [primaryClientId, setPrimaryClientId] = useState("")
	const [secondaryClientId, setSecondaryClientId] = useState("")
	const [error, setError] = useState("")
	const [success, setSuccess] = useState("")
	const [loading, setLoading] = useState(false)
	const [previewData, setPreviewData] = useState(null)
	const [showPreview, setShowPreview] = useState(false)

	const previewMerge = async () => {
		try {
			setLoading(true)
			setError("")
			setSuccess("")

			// Get counts of documents for the secondary client
			const [enquiriesSnapshot, removedEnquiriesSnapshot, quotesSnapshot, bookingsSnapshot, logsSnapshot] = await Promise.all([
				db.collection("enquiries").where("client", "==", secondaryClientId).get(),
				db.collection("enquiries_removed").where("enquiry.client", "==", secondaryClientId).get(),
				db.collection("quotes").where("client", "==", secondaryClientId).get(),
				db.collection("bookings").where("client", "==", secondaryClientId).get(),
				db.collection(`clients/${secondaryClientId}/logs`).get(),
			])

			setPreviewData({
				enquiries: enquiriesSnapshot.size,
				removedEnquiries: removedEnquiriesSnapshot.size,
				quotes: quotesSnapshot.size,
				bookings: bookingsSnapshot.size,
				logs: logsSnapshot.size,
			})
			setShowPreview(true)
		} catch (err) {
			setError("Error previewing merge: " + err.message)
		} finally {
			setLoading(false)
		}
	}

	const mergeClients = async () => {
		try {
			setLoading(true)
			setError("")
			setSuccess("")

			const batch = db.batch()

			// Update enquiries
			const enquiriesSnapshot = await db.collection("enquiries").where("client", "==", secondaryClientId).get()
			enquiriesSnapshot.docs.forEach((doc) => {
				batch.update(doc.ref, { client: primaryClientId })
			})

			// Update removed enquiries
			const removedEnquiriesSnapshot = await db.collection("enquiries_removed").where("enquiry.client", "==", secondaryClientId).get()
			removedEnquiriesSnapshot.docs.forEach((doc) => {
				batch.update(doc.ref, { "enquiry.client": primaryClientId })
			})

			// Update quotes
			const quotesSnapshot = await db.collection("quotes").where("client", "==", secondaryClientId).get()
			quotesSnapshot.docs.forEach((doc) => {
				batch.update(doc.ref, { client: primaryClientId })
			})

			// Update bookings
			const bookingsSnapshot = await db.collection("bookings").where("client", "==", secondaryClientId).get()
			bookingsSnapshot.docs.forEach((doc) => {
				batch.update(doc.ref, { client: primaryClientId })
			})

			// Copy over logs from secondary client to primary client
			const logsSnapshot = await db.collection(`clients/${secondaryClientId}/logs`).get()
			logsSnapshot.docs.forEach((doc) => {
				const logData = doc.data()
				batch.set(db.collection(`clients/${primaryClientId}/logs`).doc(doc.id), logData)
			})

			// Delete the secondary client
			const secondaryClientRef = db.collection("clients").doc(secondaryClientId)
			batch.delete(secondaryClientRef)

			// Commit all changes
			await batch.commit()

			// Add a summary log to the primary client
			await db.collection(`clients/${primaryClientId}/logs`).add({
				type: "CLIENT_MERGED",
				badge: "INFO",
				message: `Merged client account ${secondaryClientId} into this account. Transferred: ${previewData.enquiries} enquiries, ${previewData.removedEnquiries} removed enquiries, ${previewData.quotes} quotes, ${previewData.bookings} bookings, and ${previewData.logs} logs.`,
				created: firebase.firestore.FieldValue.serverTimestamp(),
			})

			setSuccess("Clients merged successfully")
			setTimeout(() => {
				history.push("/clients")
			}, 2000)
		} catch (err) {
			setError("Error merging clients: " + err.message)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Tile fullPage={true}>
			<Title>
				<h1>Merge Clients</h1>
			</Title>

			<div className="merge-clients">
				{!showPreview ? (
					<div className="merge-clients__form">
						<div className="merge-clients__field">
							<Input
								label="Primary Client ID"
								value={primaryClientId}
								onChange={setPrimaryClientId}
								placeholder="Enter primary client ID"
							/>
						</div>
						<div className="merge-clients__field">
							<Input
								label="Secondary Client ID"
								value={secondaryClientId}
								onChange={setSecondaryClientId}
								placeholder="Enter secondary client ID"
							/>
						</div>
						{error && <div className="merge-clients__error">{error}</div>}
						<div className="merge-clients__actions">
							<Button
								label="Preview Changes"
								onClick={previewMerge}
								loading={loading}
								disabled={!primaryClientId || !secondaryClientId}
							/>
						</div>
					</div>
				) : (
					<div className="merge-clients__preview">
						<h2>Preview Changes</h2>
						<Table
							className="preview-table"
							headings={["Document Type", "Count"]}>
							<tr>
								<td>Active Enquiries</td>
								<td>{previewData.enquiries}</td>
							</tr>
							<tr>
								<td>Removed Enquiries</td>
								<td>{previewData.removedEnquiries}</td>
							</tr>
							<tr>
								<td>Quotes</td>
								<td>{previewData.quotes}</td>
							</tr>
							<tr>
								<td>Bookings</td>
								<td>{previewData.bookings}</td>
							</tr>
							<tr>
								<td>Logs</td>
								<td>{previewData.logs}</td>
							</tr>
						</Table>
						{error && <div className="merge-clients__error">{error}</div>}
						{success && <div className="merge-clients__success">{success}</div>}
						<div className="merge-clients__actions">
							<Button
								label="Cancel"
								onClick={() => setShowPreview(false)}
								type="secondary"
							/>
							<Button
								label="Confirm Merge"
								onClick={mergeClients}
								loading={loading}
							/>
						</div>
					</div>
				)}
			</div>
		</Tile>
	)
}

export default MergeClients
