import React, { useContext } from "react"
import { AuthContext } from "../providers/auth"
import { Switch, Route, Redirect } from "react-router-dom"
import { auth } from "../firebase"

/**
 * Route components
 */
import Error404 from "../../containers/errors/404"
import Clients from "../../containers/clients/clients"
import Client from "../../containers/client/client"
import Targets from "../../containers/targets/targets"
import Enquiries from "../../containers/enquiries/enquiries"
import Bookings from "../../containers/bookings/bookings"
import BookingWrap from "../../containers/booking/booking"
import StaffEnquiries from "../../containers/staff/staff"
import Stats from "../../containers/stats/stats"
import Conversions from "../../containers/conversions/conversions"
import Balances from "../../containers/balances/balances"
import Suppliers from "../../containers/suppliers/suppliers"
import Travellers from "../../containers/travellers/travellers"
import Partners from "../../containers/partners/partners"
import Partner from "../../containers/partner/partner"
import Leaderboard from "../../containers/leaderboard/leaderboard"
import Internal from "../../containers/internal/internal"
import MergeClients from "../../containers/merge-clients/merge-clients"
import Data from "../../containers/data/data"
import Cancellations from "../../containers/cancellations/cancellations"
import Deposits from "../../containers/deposits/deposits"
import Holidays from "../../containers/cms/holidays/holidays"
import EditHoliday from "../../containers/cms/holidays/edit/edit"
import Quoting from "../../containers/quoting/quoting"
import Courses from "../../containers/cms/courses/courses"
import EditCourse from "../../containers/cms/courses/edit/edit"
import DeletedEnquiries from "../../containers/deleted-enquiries/deleted-enquiries"
import EnquiryAssignments from "../../containers/enquiry-assignments/enquiry-assignments"
import MyQuotes from "../../containers/my-quotes/my-quotes"
import Flights from "../../containers/flights/flights"
import Buggies from "../../containers/buggies/buggies"
import AllQuotes from "../../containers/quotes/quotes"
import GiftVouchers from "../../containers/gift-vouchers/gift-vouchers"
import Charting from "../../containers/charting/charting"
import Transfers from "../../containers/transfers/transfers"
import ClientReceipts from "../../containers/client-receipts/client-receipts"
import Tracking from "../../containers/tracking/tracking"
import TrackingAnalytics from "../../containers/tracking-analytics/tracking-analytics"
import SupplierReceipts from "../../containers/supplier-receipts/supplier-receipts"
import CurrenciesOwed from "../../containers/currencies-owed/currencies-owed"
import MarginAttribution from "../../containers/margin-attribution/margin-attribution"
import BookingMargins from "../../containers/booking-margins/booking-margins"
import ItemisedStats from "../../containers/itemised-stats/itemised-stats"
import HistoricalTargets from "../../containers/historical-targets/historical-targets"
import ManagerAnalytics from "../../containers/manager-analytics/manager-analytics"
import ConversionsBreakdown from "../../containers/conversions-breakdown/conversions-breakdown"
import CommissionSettlement from "../../containers/commission-settlement/commission-settlement"
import { Masters2025 } from "../../containers/events"

/**
 * Returns the routes for staff on the app
 */
function PageRoutes() {
	/**
	 * Deconstruct the user object from the auth context
	 */
	const { user } = useContext(AuthContext)

	return (
		<Switch>
			{/* Admin route for targets */}
			{user?.is_admin && (
				<Route
					path="/targets"
					component={Targets}
				/>
			)}
			{(user?.is_admin || user?.is_manager) && (
				<Route
					path="/historical-targets"
					component={HistoricalTargets}
				/>
			)}

			{/* Manager analytics route for admins and managers */}
			{(user?.is_admin || user?.is_manager) && (
				<Route
					path="/manager-analytics"
					component={ManagerAnalytics}
				/>
			)}

			{/* Admin route for internal settings */}
			{user?.is_admin && (
				<Route
					path="/internal"
					component={Internal}
				/>
			)}

			{/* Merge clients route */}
			{user?.is_super_admin && (
				<Route
					path="/merge-clients"
					component={MergeClients}
				/>
			)}

			{/* Admin route for data settings */}
			{user?.is_admin && (
				<Route
					path="/data"
					component={Data}
				/>
			)}

			{!user?.crm_manager && (
				<Route
					path="/charting"
					component={Charting}
				/>
			)}

			{/* Admin route for viewing deleted enquiries */}
			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"ndo3zxkvoMdYJYg7xyeqSQshDQw1",
				"8lIXQuliWhPJXrgi0rM7dJ3e9kM2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"8lIXQuliWhPJXrgi0rM7dJ3e9kM2",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/deleted-enquiries"
					component={DeletedEnquiries}
				/>
			)}

			{/* Admin route for viewing deleted enquiries */}
			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"neW9eVfyCNW5ILndUxMwNrXQxJD3",
				"8lIXQuliWhPJXrgi0rM7dJ3e9kM2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"8lIXQuliWhPJXrgi0rM7dJ3e9kM2",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/enquiry-assignments"
					component={EnquiryAssignments}
				/>
			)}

			{/* Admin route for viewing all quotes */}
			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"neW9eVfyCNW5ILndUxMwNrXQxJD3",
				"8lIXQuliWhPJXrgi0rM7dJ3e9kM2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"8lIXQuliWhPJXrgi0rM7dJ3e9kM2",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/quotes"
					component={AllQuotes}
				/>
			)}

			{/* App page routes for staff accounts */}
			{!user?.crm_manager && (
				<Route
					path="/clients"
					component={Clients}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/client/:id"
					component={Client}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/enquiries"
					component={Enquiries}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/bookings"
					component={Bookings}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/booking/:id"
					component={BookingWrap}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/staff"
					component={StaffEnquiries}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/stats"
					component={Stats}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/flights"
					component={Flights}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/transfers"
					component={Transfers}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/buggies"
					component={Buggies}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/conversions"
					component={Conversions}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/balances"
					component={Balances}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/suppliers"
					component={Suppliers}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/travellers"
					component={Travellers}
				/>
			)}
			{/* Admin route for client receipts */}
			{user?.is_admin && (
				<Route
					path="/client-receipts"
					component={ClientReceipts}
				/>
			)}
			{user?.is_admin && (
				<Route
					path="/supplier-receipts"
					component={SupplierReceipts}
				/>
			)}
			{user?.is_admin && (
				<Route
					path="/margin-attribution"
					component={MarginAttribution}
				/>
			)}
			{user?.is_admin && (
				<Route
					path="/booking-margins"
					component={BookingMargins}
				/>
			)}
			{user?.is_admin && (
				<Route
					path="/conversions-breakdown"
					component={ConversionsBreakdown}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/my-quotes"
					component={MyQuotes}
				/>
			)}

			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"Z6EM3kFyJ8QiJMzMsYm6TwrWaUH3",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
				"ndo3zxkvoMdYJYg7xyeqSQshDQw1",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/partners"
					component={Partners}
				/>
			)}

			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"Z6EM3kFyJ8QiJMzMsYm6TwrWaUH3",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
				"neW9eVfyCNW5ILndUxMwNrXQxJD3",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/partner/:collection/:id"
					component={Partner}
				/>
			)}

			{!user?.crm_manager && (
				<Route
					path="/leaderboard"
					component={Leaderboard}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/cancellations"
					component={Cancellations}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/deposits"
					component={Deposits}
				/>
			)}
			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"Z6EM3kFyJ8QiJMzMsYm6TwrWaUH3",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
				"neW9eVfyCNW5ILndUxMwNrXQxJD3",
				"mJQ6mUzWPMhVyCQGR4nNF6MDBoo2",
				"FJwopC1ZJJVDQG1lRlSVEUhghHu1",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/gift-vouchers"
					component={GiftVouchers}
				/>
			)}
			{!user?.crm_manager && (
				<Route
					path="/quoting"
					component={Quoting}
				/>
			)}

			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"Z6EM3kFyJ8QiJMzMsYm6TwrWaUH3",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
				"neW9eVfyCNW5ILndUxMwNrXQxJD3",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/manage/holidays"
					component={Holidays}
				/>
			)}

			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"Z6EM3kFyJ8QiJMzMsYm6TwrWaUH3",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
				"ndo3zxkvoMdYJYg7xyeqSQshDQw1",
				"neW9eVfyCNW5ILndUxMwNrXQxJD3",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/manage/holiday/:id"
					component={EditHoliday}
				/>
			)}

			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"Z6EM3kFyJ8QiJMzMsYm6TwrWaUH3",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
				"ndo3zxkvoMdYJYg7xyeqSQshDQw1",
				"neW9eVfyCNW5ILndUxMwNrXQxJD3",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/manage/courses"
					component={Courses}
				/>
			)}

			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"Z6EM3kFyJ8QiJMzMsYm6TwrWaUH3",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
				"ndo3zxkvoMdYJYg7xyeqSQshDQw1",
				"neW9eVfyCNW5ILndUxMwNrXQxJD3",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/manage/course/:id"
					component={EditCourse}
				/>
			)}

			{/* Admin route for tracking */}
			{(user?.is_admin || auth.currentUser.uid === "V0EpXEeyopbFR8jR3AOflLSqeTM2") && (
				<Route
					path="/tracking"
					component={Tracking}
				/>
			)}

			{/* Admin route for viewing tracking analytics */}
			{(user?.is_admin || user?.crm_manager || auth.currentUser.uid === "V0EpXEeyopbFR8jR3AOflLSqeTM2") && (
				<Route
					path="/utm-parameters"
					component={TrackingAnalytics}
				/>
			)}

			{/* Admin route for currencies owed */}
			{user?.is_admin && (
				<Route
					path="/currencies-owed"
					component={CurrenciesOwed}
				/>
			)}

			{/* Admin route for itemised stats */}
			{user?.is_admin && (
				<Route
					path="/itemised-stats"
					component={ItemisedStats}
				/>
			)}

			{/* Admin route for The Masters 2025 event */}
			{user?.is_admin && (
				<Route
					path="/events/masters-2025"
					component={Masters2025}
				/>
			)}

			{/* Admin route for commission settlement */}
			{user?.is_admin && (
				<Route
					path="/commission-settlement"
					component={CommissionSettlement}
				/>
			)}

			{/* Page redirects for staff accounts */}
			<Redirect
				from="/"
				to="/clients"
				exact
			/>
			<Redirect
				from="/login"
				to="/clients"
				exact
			/>
			{user?.crm_manager && (
				<Redirect
					from="/clients"
					to="/utm-parameters"
					exact
				/>
			)}

			{/* Error pages */}
			<Route
				path="*"
				component={Error404}
			/>
		</Switch>
	)
}

export default PageRoutes
